import daDKLinks from "./links/da-DK.json";
import deDeLinks from "./links/de-DE.json";
import enAuLinks from "./links/en-AU.json";
import enUsLinks from "./links/en-US.json";
import enGbLinks from "./links/en-GB.json";
import esEsLinks from "./links/es-ES.json";
import frFrLinks from "./links/fr-FR.json";
import itItLinks from "./links/it-IT.json";
import nlNlLinks from "./links/nl-NL.json";
import plPLLinks from "./links/pl-PL.json";
import ruRuLinks from "./links/ru-RU.json";
import svSeLinks from "./links/sv-SE.json";

export const staticLinks = {
  "da-dk": daDKLinks,
  "de-de": deDeLinks,
  "en-au": enAuLinks,
  "en-us": enUsLinks,
  "en-gb": enGbLinks,
  "es-es": esEsLinks,
  "fr-fr": frFrLinks,
  "it-it": itItLinks,
  "nl-nl": nlNlLinks,
  "pl-pl": plPLLinks,
  "ru-ru": ruRuLinks,
  "sv-se": svSeLinks
};
