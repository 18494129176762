import daDKTranslations from "./da-DK.json";
import deDeTranslations from "./de-DE.json";
import enAuTranslations from "./en-AU.json";
import enUsTranslations from "./en-US.json";
import enGbTranslations from "./en-GB.json";
import esEsTranslations from "./es-ES.json";
import frFrTranslations from "./fr-FR.json";
import itItTranslations from "./it-IT.json";
import nlNlTranslations from "./nl-NL.json";
import plPLTranslations from "./pl-PL.json";
import ruRuTranslations from "./ru-RU.json";
import svSeTranslations from "./sv-SE.json";
import { staticLinks } from "./static";

const translations = {
  "da-dk": daDKTranslations,
  "de-de": deDeTranslations,
  "en-au": enAuTranslations,
  "en-us": enUsTranslations,
  "en-gb": enGbTranslations,
  "es-es": esEsTranslations,
  "fr-fr": frFrTranslations,
  "it-it": itItTranslations,
  "nl-nl": nlNlTranslations,
  "pl-pl": plPLTranslations,
  "ru-ru": ruRuTranslations,
  "sv-se": svSeTranslations
};

export default languageCode => {
  return {
    ...translations[languageCode.toLowerCase()],
    ...staticLinks[languageCode.toLowerCase()]
  };
};
